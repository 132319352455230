import React, {useEffect} from "react";
import {
    AccessHome,
    MiniBanner,
    TextMiniBar,
    TItleminiBaner
} from "../../theme/theme.elements";
import aboutImagen from '../../assets/about.png'
import {Link} from "react-router-dom";
import {Nosotros} from "./nosotros";

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);
    return (
        <React.Fragment>
            <MiniBanner image={aboutImagen}>
                <TItleminiBaner>Sobre nosotros</TItleminiBaner>
                <TextMiniBar><AccessHome as={Link} to={"/"}>Inicio</AccessHome> / Nosotros.</TextMiniBar>
            </MiniBanner>
            <Nosotros />
        </React.Fragment>
    );
}
export {About};