import React, {useEffect} from "react";
import {Hero} from "../components/heroSection";
import {CardHome} from "../components/cardsHome";
import {
    Sectiondos,
    Consectiodos,
    Imgsectiondos,
    H3sectiodos,
    Psectiodos,
} from "../theme/home.elements";
import {Cultura} from "../pages/home/cultura";
import {Opinions} from "../pages/home/opinions";
import {SectionSeparate} from "../components/sectionSeparate";
import services_happy from '../assets/service_happy.png'
import {QuienesSomosHome} from "./home/quienesSomosHome";

function Home() {
    window.scrollTo(0, 0);
    return (
        <React.Fragment>
            <Hero/>
            <CardHome/>
            <SectionSeparate imagen={services_happy} titleseparate={"MIGRACIONES DE CORREO"}
                             titleFocalice={'EXPERTOS EN MIGRACIÓN'}
                             description={'Optimiza Oportunidades, Impulsa la Eficiencia Empresarial con Nuestros Correos Corporativos de Excelencia.'}/>

            <QuienesSomosHome/>
            <Cultura/>
            <Opinions/>
        </React.Fragment>
    );
}

export {Home};
