import React, {useEffect, useState} from "react";
import {
    Cantidades,
    ContenedorGeneral,
    SectionInformation,
    SectionProyects,
    TextoInformativo
} from "../theme/theme.elements";
import imagen from '../assets/about.png'
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

function Projects() {

    const data = [
        {
            "icon": <WorkRoundedIcon/>,
            "number": 100,
            "title": "Compromiso"
        },
        {
            "icon": <HowToRegRoundedIcon/>,
            "number": 100,
            "title": "Dedicación"
        },
        {
            "icon": <VerifiedRoundedIcon/>,
            "number": 100,
            "title": "Éxito"
        },
    ]
    const [incrementalNumbers, setIncrementalNumbers] = useState(Array(data.length).fill(0));
    useEffect(() => {
        const interval = setInterval(() => {
            setIncrementalNumbers((prevNumbers) => {
                return prevNumbers.map((prevNum, index) => {
                    const targetNum = data[index].number;
                    const step = Math.ceil(targetNum / 50); // Número de pasos para llegar al valor final
                    return prevNum + step < targetNum ? prevNum + step : targetNum;
                });
            });
        }, 50);
        return () => clearInterval(interval);
    }, []);
    return (
        <React.Fragment>
            <SectionProyects background={imagen}>
                <ContenedorGeneral>
                    {data.map((item, index) => (
                        <SectionInformation key={index}>
                            {item.icon}
                            <Cantidades>{incrementalNumbers[index]}%</Cantidades>
                            <TextoInformativo>{item.title}</TextoInformativo>
                        </SectionInformation>
                    ))}
                </ContenedorGeneral>
            </SectionProyects>
        </React.Fragment>
    );
}

export {Projects};