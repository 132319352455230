import React, {useEffect} from "react";
import {
    ButtonConocenos, ButtonConocenosdos,
    CardSectionService,
    Columna1,
    Columna2,
    Columna2Derecha,
    Columna2Izquierda,
    ContainerAboutHome,
    ContainerImageAboutHome,
    ContainerInformative, ContenedorButton,
    ImageAboutHomeValores,
    ImagenAboutHome, PAboutHome,
    ParafoTextInfoComplement,
    Quienessomos,
    Section,
    SectionServiceInfo,
    SectionText,
    TextoAbajo,
    TextoArriba, TitleAboutHome,
    TitleTextInfo,
    TitleTextInfoComplement
} from "../theme/theme.elements";
import imagenes from "../assets/about.png"
import mision from "../assets/mision.jpg"
import vision from "../assets/vision.jpg"
import propuesta from "../assets/propuesta.jpg"
import {Link} from "react-router-dom";

function CardHome() {
    return (
        <React.Fragment>
            <Quienessomos>
                <ContainerAboutHome>
                    <ImagenAboutHome src={imagenes} alt="Image About"/>
                    <ContainerInformative>
                        <TitleAboutHome>Sobre nosotros</TitleAboutHome>
                        <PAboutHome>
                            En MS-IT nos enorgullece ser líderes en la entrega de servicios tecnológicos con un enfoque
                            especializado en soluciones basadas en tecnologías Microsoft. Nos destacamos por nuestra
                            pasión por la innovación, la excelencia técnica y el compromiso con la satisfacción del
                            cliente.
                            {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut*/}
                            {/*labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco*/}
                            {/*laboris nisi ut aliquip ex ea commodo consequat. <br/><br/>*/}
                            {/*Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla*/}
                            {/*pariatur.*/}
                        </PAboutHome>
                        <ContainerImageAboutHome>
                            <ImageAboutHomeValores src={mision} alt="mision"/>
                            <ImageAboutHomeValores src={vision} alt="vision"/>
                            <ImageAboutHomeValores src={propuesta} alt="propuesta"/>
                        </ContainerImageAboutHome>
                        <ContenedorButton>
                            <ButtonConocenosdos as={Link} to={'/About'}>
                                Conocenos
                            </ButtonConocenosdos>
                        </ContenedorButton>

                    </ContainerInformative>

                </ContainerAboutHome>

            </Quienessomos>
        </React.Fragment>
    );
}

export {CardHome};
