import React from 'react';
import {Link} from 'react-router-dom';
import {
    AccesoServicio,
    ImgMiniCard,
    LiCardsMini,
    ListaSinSubrayado,
    MiniCard,
    MiniSidebarall, TituloServicios
} from "../../theme/theme.elements";
import {usePoliticasContext} from "../../globalcontext/globalcontext";

const MiniSidebarPolicy = (props) => {
    const [politicasms, ] = usePoliticasContext();
    return (
        <React.Fragment>
            <MiniSidebarall>
                <TituloServicios>Politicas</TituloServicios>
                <ListaSinSubrayado>
                    {politicasms.map(unit => (
                        <LiCardsMini key={unit.id}>
                            <MiniCard>
                                <ImgMiniCard src={unit.imagen} alt="Icono de Servicio" />
                                <AccesoServicio as={Link} to={`/Policies/${unit.id}`}>{unit.label}</AccesoServicio>
                            </MiniCard>
                        </LiCardsMini>
                    ))}
                </ListaSinSubrayado>
            </MiniSidebarall>
        </React.Fragment>
    );
};

export default MiniSidebarPolicy;
