import React from 'react';
import {Routers} from "./routers";
import {FloatingWhatsAppButton} from "./components/whatsapp";
import {useGlobalContext} from "./globalcontext/globalcontext";

function App() {
    const [empresaInfo, ] = useGlobalContext();
    window.scrollTo(0, 0);

    return (
        <React.Fragment>
            <Routers/>
            <FloatingWhatsAppButton phoneNumber={empresaInfo.whatsapp} message={empresaInfo.whatsapp_message} />
        </React.Fragment>
    );
}

export default App;
