import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Logo,
    MobileMenuBackground,
    MobileMenuIcon,
    NavbarWrapper,
    NavItem,
    NavLink,
    NavOptions,
} from "../theme/theme.elements";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalContext } from "../globalcontext/globalcontext";

function Navbar() {
    const [empresaInfo] = useGlobalContext();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isNavbarFixed, setNavbarFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setNavbarFixed(scrollY > 100);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <React.Fragment>
            <NavbarWrapper isNavbarFixed={isNavbarFixed}>
                <NavLink as={Link} to={'/'} isNavbarFixed={isNavbarFixed}>
                    <Logo>
                        <img src={isNavbarFixed ? empresaInfo.logo_navbar : empresaInfo.logo_navbar} alt="Logo de la empresa" isNavbarFixed={isNavbarFixed} />
                    </Logo>
                </NavLink>
                <MobileMenuIcon onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon sx={{ color: "#FFF" }} />}
                </MobileMenuIcon>
                <NavOptions isMobileMenuOpen={isMobileMenuOpen}>
                    <NavItem>
                        <NavLink as={Link} to={"/"} isNavbarFixed={isNavbarFixed} onClick={closeMobileMenu}>
                            Inicio
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink as={Link} to={"/About"} isNavbarFixed={isNavbarFixed} onClick={closeMobileMenu}>
                            Quienes somos
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink as={Link} to={"/Service"} isNavbarFixed={isNavbarFixed} onClick={closeMobileMenu}>
                            Servicios
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink as={Link} to={"/Contact"} isNavbarFixed={isNavbarFixed} onClick={closeMobileMenu}>
                            Contáctenos
                        </NavLink>
                    </NavItem>
                </NavOptions>
            </NavbarWrapper>
            <MobileMenuBackground isMobileMenuOpen={isMobileMenuOpen} onClick={closeMobileMenu} />
        </React.Fragment>
    );
}

export { Navbar };
