import React, {useEffect, useState} from 'react';
import {
    ButtonConocenos,
    Descriptions,
    Dinamico,
    HeroSection,
    ImageContainer,
    TextContainer,
    Title1,
    Titulo,
    Titulo2
} from "../theme/theme.elements";
import fondo1 from "../assets/fondo3.png"
import {Link} from "react-router-dom";

function Hero() {
    const words = ["Innovadora", "Agilidad", "Eficiente"];
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        const handleMouseMove = (e) => {
            setMouseX(e.clientX);
            setMouseY(e.clientY);
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return (
        <HeroSection fondo1={fondo1}>
            <TextContainer>
                <Title1>Migración de correo empresarial.</Title1>
                <Titulo2>Trabaje con nosotros.</Titulo2>
                <Descriptions>Lideramos soluciones de migración de correo empresarial, simplificando la transición a la
                    nube de manera <Dinamico>{words[currentWordIndex]}</Dinamico> con nuestras avanzadas soluciones 365.
                    Nos destacamos por la seguridad de la información y ofrecemos servicios personalizados,
                    consolidándonos como su socio estratégico en la transformación digital para un crecimiento
                    empresarial sólido.
                </Descriptions>
                <ButtonConocenos as={Link} to={'/About'}>
                    Conocenos
                </ButtonConocenos>
            </TextContainer>
        </HeroSection>
    );
}

export {Hero};
