import React from "react";
import {
    CardService,
    ContainerService,
    IconCard,
    ParagrafService, ParrafoCardService,
    SectionService, TitleCardService,
    TitleService
} from "../../theme/theme.elements";
import {useMigracionContext} from "../../globalcontext/globalcontext";

function Servicesone() {
    const [migracion, ] = useMigracionContext()
    window.scrollTo(0, 0);
    return (
        <React.Fragment>
            <SectionService>
                <TitleService>Servicios de Migración de Correo Microsoft 365.</TitleService>
                <ParagrafService>
                    Somos líderes en la industria, ofreciendo excelencia en servicios de migración de correo para
                    Microsoft 365. Nuestro enfoque meticuloso garantiza traslados sin complicaciones, optimizando la
                    eficiencia empresarial y la continuidad operativa.
                </ParagrafService>

                <ContainerService>
                    {migracion.map((item, index) => (
                        <CardService key={index}>
                            <IconCard src={item.icon} />
                            <TitleCardService>{item.title}</TitleCardService>
                            <ParrafoCardService>{item.paragraph}</ParrafoCardService>
                        </CardService>
                    ))}
                </ContainerService>
            </SectionService>

        </React.Fragment>
    );
}

export {Servicesone};