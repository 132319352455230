import React from "react";
import {Link, useParams} from 'react-router-dom';
import services from "../../assets/services.jpg";
import {AccessHome, ContainerMiniSidebar, MiniBanner, TextMiniBar, TItleminiBaner} from "../../theme/theme.elements";
import MiniSidebar from "./miniSidebar";
import {Description_service} from "./description_service";
import {useServiciosContext} from "../../globalcontext/globalcontext";

function Service_detail() {
    const {id} = useParams();
    const [servicios, ] = useServiciosContext();
    const servicio = servicios.find(servicio => servicio.id === parseInt(id));
    window.scrollTo(0, 0);

    return (
        <React.Fragment>
            <MiniBanner image={services}>
                <TItleminiBaner>Servicios</TItleminiBaner>
                <TextMiniBar><AccessHome as={Link} to={"/"}>Inicio</AccessHome> / <AccessHome as={Link}
                                                                                              to={"/Service"}>Servicios</AccessHome> / {servicio.title}.</TextMiniBar>
            </MiniBanner>
            <ContainerMiniSidebar>
                <MiniSidebar valor={id}/>
                <Description_service valor={id}/>
            </ContainerMiniSidebar>
        </React.Fragment>
    );
}

export {Service_detail};