import React, {useState} from 'react';
import {LogoWhatsapp, StyledWhatsAppButton} from "../theme/theme.elements";
import wahtsapplogo from "../assets/wahtsapplogo.png"

const FloatingWhatsAppButton = ({phoneNumber, message}) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const whatsappLink = isMobile
        ? `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
        : `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

    return (
        <StyledWhatsAppButton href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <LogoWhatsapp src={wahtsapplogo}/>
        </StyledWhatsAppButton>
    );
};

export {FloatingWhatsAppButton};
