import React from "react";
import {
    Container, ContainerTitles,
    Description,
    Image, InformativeAbout,
    LeftColumn, LeftColumninv, ParrafosAbputme, parrafosAbputme,
    RightColumn, RightColumninv,
    SubtitleNosotros, TitleAboutme,
    TitleNosotros
} from "../../theme/theme.elements";
import mision from '../../assets/mision.jpg'
import vision from '../../assets/vision.jpg'
import propuesta from '../../assets/propuesta.jpg'


function Nosotros() {
    return (
        <React.Fragment>
            <InformativeAbout>
                <TitleAboutme>Transforma tu Trayectoria Digital con MS-IT Soluciones</TitleAboutme>
                <ParrafosAbputme>
                    En MS-IT Soluciones, no solo comprendemos la importancia de la transformación digital, sino que nos apasiona liderar el camino hacia un futuro empresarial más eficiente y colaborativo. Somos más que expertos en migraciones, somos tu socio estratégico en el viaje hacia la excelencia digital.
                    Imagínate una transición sin complicaciones hacia Microsoft 365, donde cada paso está cuidadosamente planificado, cada dato se migra sin interrupciones y se capacita a cada trabajador para que pueda aprovechar al máximo las herramientas que definen el panorama empresarial actual.
                    Con una visión clara de ser líderes en la transformación digital, en MS-IT Soluciones, nos comprometemos a proporcionar servicios de migración que no solo cumplen con tus expectativas, sino que las superan. Nuestra misión es simplificar y optimizar tu trayectoria digital, brindando soluciones que no solo transforman tu infraestructura tecnológica, sino también tu capacidad para innovar y prosperar en un mundo digital en constante evolución.
                    Adéntrate en la sección "Nosotros" y descubre cómo podemos ser tu catalizador en la travesía hacia el éxito digital. Tu futuro digital comienza aquí, con MS-IT Soluciones.
                </ParrafosAbputme>
            </InformativeAbout>
            <Container>
                <LeftColumn>
                    <TitleNosotros>Misión</TitleNosotros>
                    <SubtitleNosotros>Conoce nuestra misión</SubtitleNosotros>
                    <Description>
                        Facilitar la transformación digital de nuestros clientes a través de soluciones tecnológicas
                        avanzadas basadas en plataformas Microsoft. Nos esforzamos por impulsar el crecimiento
                        empresarial, mejorar la eficiencia operativa y fomentar la colaboración a través de soluciones
                        que aprovechan al máximo las herramientas y servicios de Microsoft.
                    </Description>
                </LeftColumn>
                <RightColumn>
                    <Image src={mision} alt="Imagen"/>
                </RightColumn>
            </Container>
            <Container>
                <LeftColumninv>
                    <Image src={vision} alt="Imagen"/>
                </LeftColumninv>
                <RightColumninv>
                    <TitleNosotros>Visión</TitleNosotros>
                    <SubtitleNosotros>Conoce nuestra vision</SubtitleNosotros>
                    <Description>
                        En MS-IT Soluciones, nos comprometemos a simplificar y optimizar la transición de tu empresa
                        hacia el ecosistema Microsoft, proporcionando servicios de migración especializados que
                        garantizan la continuidad operativa, la seguridad de los datos y la mejora sostenible de la
                        eficiencia y colaboración empresarial.
                    </Description>
                </RightColumninv>
            </Container>
            <Container>
                <LeftColumn>
                    <TitleNosotros>Propuesta de valor</TitleNosotros>
                    <SubtitleNosotros>Unete a nosotros</SubtitleNosotros>
                    <Description>
                        En MS-IT Soluciones, entendemos que la evolución digital es esencial para el éxito empresarial
                        en la era moderna. Nos enorgullece ofrecer servicios de migración de primera categoría que
                        transforman no solo la infraestructura tecnológica de tu empresa, sino también su capacidad para
                        innovar, colaborar y prosperar en el entorno digital.
                    </Description>
                </LeftColumn>
                <RightColumn>
                    <Image src={propuesta} alt="Imagen"/>
                </RightColumn>
            </Container>
        </React.Fragment>
    );
}

export {Nosotros};