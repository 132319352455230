import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Navbar} from "./components/navs";
import {Footer} from "./components/foorter";
import {BrowserRouter} from "react-router-dom";
import {GlobalProvider} from "./globalcontext/globalcontext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <GlobalProvider>
            <Navbar/>
            <App/>
            <Footer/>
        </GlobalProvider>
    </BrowserRouter>
);
