import React from "react";
import {
    CardSectionService, Columna1, Columna2, Columna2Derecha, Columna2Izquierda, ParafoTextInfoComplement, Section,
    SectionServiceInfo,
    SectionText, TextoAbajo, TextoArriba,
    TitleTextInfo,
    TitleTextInfoComplement
} from "../../theme/theme.elements";


function ServiceDescription() {
    window.scrollTo(0, 0);

    return (
        <React.Fragment>
            <SectionServiceInfo>
                <CardSectionService>
                    <SectionText>
                        <TitleTextInfo>Transforma </TitleTextInfo>
                        <TitleTextInfoComplement>
                            Tu Empresa
                        </TitleTextInfoComplement>
                        <ParafoTextInfoComplement>
                            Líderes en Soluciones de Migración de Correo Empresarial
                        </ParafoTextInfoComplement>
                    </SectionText>
                    <Section>
                        <Columna1>
                            <p>
                                En la vanguardia de la transformación empresarial, nuestra empresa lidera la excelencia
                                en servicios de migración de correo para Microsoft 365. Proporcionamos soluciones a
                                medida que trascienden los límites, optimizando la eficiencia operativa y asegurando un
                                crecimiento empresarial sostenible.
                                <br/>
                            </p>
                            <p>
                                Con una experiencia inigualable, garantizamos migraciones sin contratiempos,
                                fortaleciendo la seguridad de tus datos críticos. Más que un servicio, somos tu socio
                                estratégico, ofreciendo soporte continuo, capacitación avanzada y un compromiso
                                inquebrantable con tu éxito. Únete a nosotros y transforma tu empresa hacia nuevas
                                alturas de productividad y prosperidad.
                            </p>
                        </Columna1>
                        <Columna2>
                            <TextoArriba>
                                <p>
                                    "Destaca y evoluciona con nuestra vanguardia en servicios de migración de correo
                                    para Microsoft 365. Impulsamos la eficiencia, seguridad y crecimiento. ¡Convierte
                                    desafíos en oportunidades con nosotros!"
                                </p>
                            </TextoArriba>
                            <TextoAbajo>
                                <Columna2Izquierda>
                                    <ul>
                                        <li>Experiencia Profesional</li>
                                        <li>Optimización Eficiente</li>
                                        <li>Seguridad Garantizada</li>
                                        <li>Soporte Continuo</li>
                                    </ul>
                                </Columna2Izquierda>
                                <Columna2Derecha>
                                    <ul>
                                        <li>Capacitación Avanzada</li>
                                        <li>Personalización Integral</li>
                                        <li>Máxima Satisfacción del Cliente</li>
                                    </ul>
                                </Columna2Derecha>
                            </TextoAbajo>
                        </Columna2>
                    </Section>
                </CardSectionService>
            </SectionServiceInfo>
        </React.Fragment>
    );
}

export {ServiceDescription}