import React, {useState} from "react";
import {Link} from 'react-router-dom';
import {
    Column,
    ColumnTitle, CompanyInfo, Copyright, EmailEmpresa,
    Footercontainer,
    FooterWrapper,
    LinkItem,
    LinkList,
    Logo, LogoFooter, NavItem, NavLink, NavLinkFooter
} from "../theme/theme.elements";
import {useGlobalContext, usePoliticasContext, useSocialContext} from '../globalcontext/globalcontext';


function Footer() {
    const [politicasms, ] = usePoliticasContext();
    const [empresaInfo, ] = useGlobalContext();
    const [socialLinksData] = useSocialContext()

    const handleCrearCorreo = () => {
        window.location.href = `mailto:${empresaInfo.correo}`;
    };


    return (
        <FooterWrapper>
            <Footercontainer>
                <Column>
                    <NavLink as={Link} to={'/'}>
                        <LogoFooter>
                            <img src={empresaInfo.logo_footer} alt="Logo de la empresa"/>
                        </LogoFooter>
                    </NavLink>
                </Column>
                <Column>
                    <ColumnTitle>Enlaces</ColumnTitle>
                    <LinkList>
                        <NavItem>
                            <NavLinkFooter as={Link} to={"/"}>Inicio</NavLinkFooter>
                        </NavItem>
                        <NavItem>
                            <NavLinkFooter as={Link} to={"/About"}>Quiénes Somos</NavLinkFooter>
                        </NavItem>
                        <NavItem>
                            <NavLinkFooter  as={Link} to={"/Service"}>Servicios</NavLinkFooter>
                        </NavItem>
                        <NavItem>
                            <NavLinkFooter  as={Link} to={"/Contact"}>Contáctenos</NavLinkFooter>
                        </NavItem>
                    </LinkList>
                </Column>
                <Column>
                    <ColumnTitle>Políticas</ColumnTitle>
                    <LinkList>
                        {politicasms.map((link, index) => (
                            <LinkItem key={index}>
                                <NavLinkFooter as={Link} to={`/Policies/${link.id}`}>{link.label}</NavLinkFooter>
                            </LinkItem>
                        ))}
                    </LinkList>
                </Column>
                <Column>
                    <CompanyInfo>
                        <ColumnTitle>Información de la Empresa</ColumnTitle>
                        <p>Dirección: {empresaInfo.direccion}</p>
                        <p>Correo: <EmailEmpresa onClick={handleCrearCorreo} >{empresaInfo.correo}</EmailEmpresa></p>
                        <p>Celular: {empresaInfo.celular}</p>
                        {/*<p>Teléfono Fijo: {empresaInfo.telefono}</p>*/}
                    </CompanyInfo>
                </Column>
                <Column>
                    <ColumnTitle>Redes Sociales</ColumnTitle>
                    <LinkList>
                        {socialLinksData.map((socialLink, index) => (
                            <LinkItem key={index}>
                                <NavLinkFooter href={socialLink.url} target="_blank" rel="noopener noreferrer">
                                    {socialLink.label}
                                </NavLinkFooter>
                            </LinkItem>
                        ))}
                        {/*<LinkItem>*/}
                        {/*    <NavLinkFooter href="https://facebook.com">Facebookes</NavLinkFooter>*/}
                        {/*</LinkItem>*/}
                        {/*<LinkItem>*/}
                        {/*    <NavLinkFooter href="https://twitter.com">Instagram</NavLinkFooter>*/}
                        {/*</LinkItem>*/}
                        {/*<LinkItem>*/}
                        {/*    <NavLinkFooter href="https://linkedin.com">LinkedIn</NavLinkFooter>*/}
                        {/*</LinkItem>*/}
                    </LinkList>
                </Column>
            </Footercontainer>
            <Copyright>
                &copy; {new Date().getFullYear()} {empresaInfo.nombre}
            </Copyright>
        </FooterWrapper>
    )
}

export {Footer};