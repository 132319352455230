import React from "react";
import {
    DescriptionSectionSeparate,
    SectionSparate,
    SubTitleSectionSeparate,
    TitleSectionSeparate
} from "../theme/theme.elements";


function SectionSeparate(props) {
    return (
        <React.Fragment>
            <SectionSparate background={props.imagen}>
                <TitleSectionSeparate>{props.titleseparate}</TitleSectionSeparate>
                <SubTitleSectionSeparate>{props.titleFocalice}</SubTitleSectionSeparate>
                <DescriptionSectionSeparate>{props.description}</DescriptionSectionSeparate>
            </SectionSparate>
        </React.Fragment>
    );
}

export {SectionSeparate};