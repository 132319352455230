import React, {useEffect} from "react";
import {
    Sectioninformative,
    Titleinformative,
    SubTitleinformative,
    Paragrafinformative,
    Texfinformative
} from "../../theme/home.elements";
import fondo from "../../assets/cultura.jpg"

function Cultura() {
    return (
        <React.Fragment>
            <Sectioninformative imagen={fondo}>
                <Titleinformative>Nuestra</Titleinformative>
                <SubTitleinformative>CULTURA EMPRESARIAL</SubTitleinformative>
                <Paragrafinformative>Transformación Digital con Compromiso y Excelencia.</Paragrafinformative>

                <Texfinformative>En MS-IT Soluciones Informáticas, nuestra cultura empresarial está arraigada en la pasión por la innovación, la excelencia técnica y el compromiso inquebrantable con la satisfacción del cliente. Nos enorgullece ser líderes en la entrega de servicios tecnológicos con un enfoque especializado en soluciones basadas en tecnologías Microsoft.</Texfinformative>
            </Sectioninformative>
        </React.Fragment>
    );
}

export {Cultura};
