import React from "react";
import services from "../../assets/services.jpg";
import {
    ContainerDescripcionServicios, ContainerDescripcionServicios2, ImagenDescripcionServicio,
    SubtituloDescripcion, TextoDescriptivoServicio,
    TituloServicioDescripcion
} from "../../theme/theme.elements";
import {useServiciosContext} from "../../globalcontext/globalcontext";

function Description_service(props) {
    const [servicios, ] = useServiciosContext();

    const data = servicios.filter(servicio => servicio.id == props.valor)
    const contenido = data[0];

    return (
        <React.Fragment>
            <ContainerDescripcionServicios>
                <TituloServicioDescripcion>{contenido.title}</TituloServicioDescripcion>
                <SubtituloDescripcion>{contenido.subtitle}</SubtituloDescripcion>
                <ContainerDescripcionServicios2>
                    <TextoDescriptivoServicio>
                        {contenido.description}
                    </TextoDescriptivoServicio>
                    {/*<ImagenDescripcionServicio src={contenido.icon} alt="ImgServicio"/>*/}
                </ContainerDescripcionServicios2>
            </ContainerDescripcionServicios>
        </React.Fragment>
    );
}

export {Description_service};