import React from "react";
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/home";
import {About} from "./pages/about/about";
import {Services} from "./pages/services/services";
import {Contact} from "./pages/contact";
import {Service_detail} from "./pages/services/service_detail";
import {PoliticasPrivacidad} from "./pages/policy/pprivacidad";

export const Routers = () => {
    window.scrollTo(0, 0);
    return(
        <Routes>
            <Route exact path="/" element={<Home />}/>
            <Route exact path="/About" element={<About />}/>
            <Route exact path="/Service" element={<Services />}/>
            <Route exact path="/Service/:id" element={<Service_detail />}/>
            <Route exact path="/Contact" element={<Contact />}/>
            <Route exact path="/Policies/:id" element={<PoliticasPrivacidad />}/>
        </Routes>
    );
}