import styled, {keyframes} from "styled-components";

export const Cardcontainer = styled.div`
  background: crimson;
  display: flex;
  justify-content: center;
  height: 60vh;
`
export const ImagenContainer = styled.img`
  width: 100%;
  height: auto;
  background-color: darkblue;
`
export const TitleService = styled.h1`
  margin-top: 5%;
  padding: 0;
  text-align: center;
  text-transform: uppercase;

`
export const Card = styled.div`
  position: relative;
  margin: 40px 10px 30px 20px;
  width: 20%;
  height: 450px;
  border-radius: var(--r1);
  background: var(--mine-shaft-50);
  box-shadow: 0px 10px 7px 2px rgba(8, 96, 196, 0.51);
  -webkit-box-shadow: 0px 10px 7px 2px rgba(51, 51, 51, 0.51);
  -moz-box-shadow: 0px 10px 7px 2px rgba(8, 96, 196, 0.51);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:hover {
    border-radius: var(--r1);
    width: 19%;
    height: 460px;
  }
`;
export const Imgcard = styled.img`
  width: 100%;
  height: 45%;
  display: flex;
  margin-bottom: auto;
  border-top-right-radius: var(--r1);
  border-top-left-radius: var(--r1);


`
export const TextCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 100%;
  margin: 2%;
  text-align: justify;


`
export const Titlecard = styled.h4`
  font-size: var(--h5);
  color: var(--mine-shaft-800);
  padding: 0;
  margin: 0;

`
export const Pcard = styled.p`
  font-size: var(--t);
  color: var(--mine-shaft-800);
  padding: 0;
  margin: 0;
  margin-top: 3%;
  margin-bottom: 3%;
  font-size: var(--s);
`
export const Acard = styled.a`
  text-decoration: none;
  position: absolute;
  bottom: 0;
  margin: 0 0 2% 2%;
  flex-wrap: wrap;
  justify-content: space-between;
  color: var(--mine-shaft-50);

`
export const Sectiondos = styled.section`
  width: 100%;
  //height: 5%;
  //padding: 5%;
  //margin-left: 15%;
  //margin-right: 15%;
  //background: crimson;
  
`
export const SectionContainerDos = styled.section`
  display: flex;
  width: 70%;
  height: 50vh;
  margin: 0 auto 7% auto;
  @media (min-width: 100px) and (max-width: 1023px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    height: auto;
    width: 90%;
    margin: auto;
    padding: 5%;
  }
`
export const Imgsectiondos = styled.img`
  width: 40%;
  height: auto;
  margin-left: 20px;
  border-radius: var(--r1);
  box-shadow: 5px 5px 10px #888;
  @media (min-width: 100px) and (max-width: 1023px) {
    width: 60vw;
  }
`
export const Consectiodos = styled.div`
  //margin-left: 2rem;
  display: flex;
  flex-direction: column;
`

export const H3sectiodos = styled.h3`
  text-align: right;
  font-size: var(--h2);
  text-transform: uppercase;
  @media (min-width: 100px) and (max-width: 1023px) {
    text-align: center;
  }
`
export const Psectiodos = styled.p`
  text-align: right;
  font-size: var(--p);
  @media (min-width: 100px) and (max-width: 1023px) {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 0 0 5% 0;
  }
`
export const Sectioninformative = styled.section`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100vh; 
    background-image: url(${(props) => props.imagen});
    background-size: cover; 
    background-position: center;
`;

export const Titleinformative = styled.h2`
  font-size: var(--h2);
  font-weight: 100;
  padding: 5% 0 0 0;
  margin: 0;
  text-transform: uppercase;
`
export const SubTitleinformative = styled.h1`
  font-size: var(--h1);
  padding: 0 0 0 0;
  margin: 0;
`
export const Paragrafinformative = styled.p`
  font-size: var(--p);
  padding: 0 0 0 0;
  margin: 0;
  font-style: italic;
`
export const Texfinformative = styled.p`
  font-size: var(--text);
  padding: 5%;
  margin: 0 auto 0 auto;
  text-align: center;
  text-justify: inter-word;
  width: 60%;
`
export const Containeropinions = styled.section`
    display: flex;
    flex-direction: column;
    padding: 3%;
    //margin-bottom: 15%;
    align-items: center;
    //background: crimson;
`
export const Titleopinionscliente = styled.h2`
  font-size: var(--h2);
  padding: 2% 0 0 0;
  text-align: center;
`
export const Subopinionscliente = styled.p`
  font-size: var(--p);
  font-weight: 200;
  padding: 0;
  text-align: center;
  width: 60%;
  margin: 0 auto 0 auto;
  @media (min-width: 100px) and (max-width: 1023px) {
    width: 80%;
  }
`
export const Containercard = styled.div`
    //display: compact;
    //width: 60%;
    justify-content: center;
    //align-items: center;
    //margin: auto;
    background: crimson;
    //height: 50%;
    
`

export const Starcard = styled.div`
  //margin: 3% 3% 5% 0;
  //display: flex;
  //height: auto;
  //align-items: flex-end;
  //justify-content: end;
  color: #9f9f1b;
  width: 100%;
`
export const TestanteOpiniones = styled.h4`
  font-size: var(--h4);
  padding: 0;
  margin: 0;
`
export const Cardopinions = styled.div`
  padding: 0;
  margin: 0;
`
export const CardopinionsClient = styled.p`
    font-size: var(--p);
    //background: crimson;
    //padding: 1%;
    margin: 1em;
    width: auto;
`
