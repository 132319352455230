import React from 'react';
import {Link} from 'react-router-dom';
import {
    AccesoServicio,
    ImgMiniCard,
    LiCardsMini,
    ListaSinSubrayado,
    MiniCard,
    MiniSidebarall, TituloServicios
} from "../../theme/theme.elements";
import {useServiciosContext} from "../../globalcontext/globalcontext";

const MiniSidebar = (props) => {
    const [servicios, ] = useServiciosContext();
    return (
        <React.Fragment>
            <MiniSidebarall>
                <TituloServicios>Servicios</TituloServicios>
                <ListaSinSubrayado>
                    {servicios.map(servicio => (
                        <LiCardsMini key={servicio.id}>
                            <MiniCard>
                                <ImgMiniCard src={servicio.icon} alt="Icono de Servicio" />
                                <AccesoServicio as={Link} to={`/Service/${servicio.id}`}>{servicio.title}</AccesoServicio>
                            </MiniCard>
                        </LiCardsMini>
                    ))}
                </ListaSinSubrayado>
            </MiniSidebarall>
        </React.Fragment>
    );
};

export default MiniSidebar;
