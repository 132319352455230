import React from "react";
import {
    CardContainer,
    CardContainerInfo,
    CardContent, DescriptionCardSection, IconCardSection, LeerDetallesCard, ParagrafService, SectionCardCOntent,
    SectionCards,
    SectionService, TitleCardSection,
    TitleCardSectionInfo, TitleCardSectionInfoDos, TitleCardSectionInfotres, TitleService, TitleServiceTwo
} from "../../theme/theme.elements";
import services from "../../assets/services.jpg";
import {Link} from "react-router-dom";
import {useServiciosContext} from "../../globalcontext/globalcontext";

function Servicestow() {
    const [servicios,] = useServiciosContext();
    window.scrollTo(0, 0);

    return (
        <React.Fragment>
            <SectionService>
                <TitleServiceTwo>Optimización Empresarial a través de la Migración a SharePoint</TitleServiceTwo>
                <ParagrafService>
                    En MS-IT, ofrecemos servicios especializados de migración a Microsoft SharePoint para ayudarte a
                    aprovechar al máximo esta potente plataforma de colaboración y gestión de documentos. La migración a
                    SharePoint puede mejorar significativamente la eficiencia operativa de tu empresa y la colaboración
                    entre equipos. Nuestros servicios de migración están diseñados para garantizar una transición sin
                    complicaciones.
                </ParagrafService>
                <SectionCards>
                    <CardContainerInfo>
                        <CardContent>
                            <TitleCardSectionInfo>Sharepoint</TitleCardSectionInfo>
                            <TitleCardSectionInfoDos>Con la mejor opción para su empresa!</TitleCardSectionInfoDos>
                            <TitleCardSectionInfotres>Creamos soluciones digitales.</TitleCardSectionInfotres>
                        </CardContent>
                    </CardContainerInfo>
                    {servicios.map((item, index) => (
                        <CardContainer key={index}>
                            <CardContent>
                                <SectionCardCOntent>
                                    <IconCardSection src={item.icon} alt=""/>
                                    <TitleCardSection>{item.title}</TitleCardSection>
                                    <DescriptionCardSection>{item.description_mini}</DescriptionCardSection>
                                    <LeerDetallesCard as={Link} to={`/Service/${item.id}`}>Leer mas
                                        detalles</LeerDetallesCard>
                                </SectionCardCOntent>
                            </CardContent>
                        </CardContainer>
                    ))}
                </SectionCards>
            </SectionService>
        </React.Fragment>
    );
}

export {Servicestow}