import React from "react";
import services from "../../assets/services.jpg";
import {
    ContainerDescripcionServicios, ContainerDescripcionServicios2, ImagenDescripcionServicio,
    TextoDescriptivoServicio, TituloServicioDescripcion
} from "../../theme/theme.elements";
import {usePoliticasContext} from "../../globalcontext/globalcontext";

function Description_policy(props) {
    const [politicasms, ] = usePoliticasContext();
    window.scrollTo(0, 0);
    const politica = politicasms.filter(pol => pol.id == props.valor)
    const contenido = politica[0];

    return (
        <React.Fragment>
            <ContainerDescripcionServicios>
                <TituloServicioDescripcion>{contenido.label}</TituloServicioDescripcion>
                <ContainerDescripcionServicios2>
                    <TextoDescriptivoServicio dangerouslySetInnerHTML={{ __html: contenido.description }} />
                            {/*{contenido.description}*/}
                    {/*</TextoDescriptivoServicio>*/}
                    {/*<ImagenDescripcionServicio src={contenido.imagen} alt="ImgPolitica"/>*/}
                </ContainerDescripcionServicios2>

            </ContainerDescripcionServicios>
        </React.Fragment>
    );
}

export {Description_policy};