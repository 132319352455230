import React, {useEffect, useRef} from "react";
import {
    Titleopinionscliente,
    Subopinionscliente,
    Containeropinions,
    Containercard, Cardopinions, Starcard, TestanteOpiniones, CardopinionsClient
} from "../../theme/home.elements";
import 'react-awesome-slider/dist/styles.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import {createGlobalStyle} from 'styled-components';


function Opinions() {
    const testimoniesData = [
        {
            testante: "",
            content: 'MS-IT Soluciones Informáticas ha sido nuestro socio tecnológico clave en el proceso de transformación digital. Su enfoque centrado en el cliente se refleja en la atención personalizada que recibimos. Gracias a su experiencia en tecnologías Microsoft, logramos optimizar nuestras operaciones y mejorar la colaboración interna. Estamos encantados con los resultados y la dedicación del equipo de MS-IT.',
            stars: 5,
        },
        {
            testante: "",
            content: 'La implementación de Microsoft 365 fue una transición suave y eficiente gracias a MS-IT. Su equipo demostró un profundo conocimiento técnico y una comprensión clara de nuestras necesidades comerciales. Ahora, con las herramientas de productividad mejoradas, experimentamos una mayor eficiencia en nuestras tareas diarias. Estamos agradecidos por la colaboración con MS-IT.',
            stars: 4,
        },
        {
            testante: "",
            content: 'La política de seguridad de MS-IT nos brinda tranquilidad. La integridad y confidencialidad de nuestros datos son prioritarias para nosotros, y MS-IT ha superado nuestras expectativas en ese aspecto. Su enfoque proactivo hacia la protección contra amenazas y vulnerabilidades nos hace sentir seguros en el entorno digital actual. ¡Gracias a MS-IT por su compromiso con la seguridad informática!',
            stars: 5,
        },
    ];
    const GlobalStyle = createGlobalStyle`
      .awssld__content {
        background-color: transparent !important; /* Anula el fondo predeterminado del carrusel */
      }
    `;

    const sliderStyles = {
        width: '100%',
        height: 'auto',
        color: '#333333FF',
    };

    return (
        <React.Fragment>
            <GlobalStyle/>

            <Containeropinions>
                <Titleopinionscliente>La opinión de nuestros clientes</Titleopinionscliente>
                <Subopinionscliente>
                    MS-IT Soluciones Informáticas ha sido nuestro socio tecnológico clave en el proceso de transformación digital. Su enfoque centrado en el cliente se refleja en la atención personalizada que recibimos. Gracias a su experiencia en tecnologías Microsoft, logramos optimizar nuestras operaciones y mejorar la colaboración interna. Estamos encantados con los resultados y la dedicación del equipo de MS-IT.
                </Subopinionscliente>
                {/*<Containercard>*/}
                {/*    <AwesomeSlider style={sliderStyles} cssModule={{GlobalStyle}}>*/}
                {/*        {testimoniesData.map((testimony, index) => (*/}
                {/*            <div key={index}>*/}
                {/*                <Cardopinions style={{textAlign: 'center'}}>*/}
                {/*                    <TestanteOpiniones>{testimony.testante}</TestanteOpiniones>*/}
                {/*                    <CardopinionsClient>{testimony.content}</CardopinionsClient>*/}
                {/*                    <Starcard>*/}
                {/*                        {Array.from({length: testimony.stars}, (_, index) => (*/}
                {/*                            <>★</>*/}
                {/*                        ))}*/}
                {/*                    </Starcard>*/}
                {/*                </Cardopinions>*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*    </AwesomeSlider>*/}
                {/*</Containercard>*/}
            </Containeropinions>
        </React.Fragment>
    );
}

export {Opinions};
