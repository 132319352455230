import styled, {keyframes} from "styled-components";
import {getMobileStepperUtilityClass} from "@mui/material";

export const NavbarWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => (props.isNavbarFixed ? 'rgba(28,25,25,0.9)' : 'transparent')};
  transition: background 0.3s ease; /* Agrega una transición suave para el cambio de color */
  position: ${props => (props.isNavbarFixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  z-index: 1000;
  width: auto;

  @media screen and (max-width: 768px) {
    z-index: 1000;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
`;

export const Logo = styled.div`
  img {
    max-width: 200px;
    height: auto;
    display: block;
    margin: 2% auto;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }
`;

export const NavOptions = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;


  @media screen and (max-width: 768px) {
    display: ${props => (props.isMobileMenuOpen ? 'block' : 'none')};
    align-self: center;
    text-align: center;

    color: var(--mine-shaft-200);
    flex-direction: column;
  }
`;

export const NavItem = styled.li`

  margin-right: 20px;

  @media screen and (max-width: 768px) {
    margin: 0;
    margin-bottom: 10px;
  }
`;

export const NavLink = styled.a`
  color: ${props => (props.isNavbarFixed ? 'var(--mine-shaft-50)' : 'var(--mine-shaft-50)')};
  font-size: var(--t);
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
`;

export const NavLinkFooter = styled.a`
  
  text-decoration: none;
  color: var(--mine-shaft-50);

  &:hover {
    text-decoration: underline;
  }

`;

export const MobileMenuIcon = styled.div`
  display: none;
  height: 50px;
  width: auto;
  @media screen and (max-width: 768px) {
    display: flex;
    align-self: end;
    position: absolute;
    margin-top: 10px;
    //color: var(--azure-radiance-200);
    justify-content: center;
    z-index: 100;
  }
`
export const MobileMenuBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9); /* Fondo semitransparente */
  display: ${props => (props.isMobileMenuOpen ? 'block' : 'none')};
  z-index: 200; /* Por encima del contenido */
`;

// hero section
export const HeroSection = styled.section`
  position: relative;
  background-image: url(${(props) => props.fondo1});
  background-size: 100% 100%;
  background-position: center;
  height: 100vh;
  text-align: left;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Color negro con 50% de transparencia */

  }

  @media screen and (max-width: 768px) {
    /* Estilos para dispositivos móviles */
    background-attachment: scroll; /* Cambia la posición de fondo para permitir el scroll */
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 60%;
  padding: 0 0 0 15%;
  text-transform: uppercase;
  @media (min-width: 100px) and (max-width: 1023px) {
    width: 100%; 
    align-items: center;
    margin: auto;
    padding: 0 0 0 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const Title1 = styled.p`
  font-size: var(--lt);
  color: var(--mine-shaft-50);
  z-index: 10;
  padding: 0;
  margin: 0;
`;
export const Titulo2 = styled.h1`
  font-size: var(--h1);
  color: var(--mine-shaft-50);
  z-index: 10;
  margin: 1% 0 1% 0;
  padding: 0;
`;

export const Descriptions = styled.p`
    font-size: var(--s);
    text-align: justify;
    color: var(--mine-shaft-50);
    z-index: 10;
    margin: 0;
    padding: 0;
    width: 70%;
    @media (min-width: 100px) and (max-width: 1023px) {
        width: 90%;
    }

`
export const ButtonConocenos = styled.a`
  font-size: var(--s);
  margin-top: 5%;
  width: auto; 
  height: auto;
  color: var(--mine-shaft-50);
  border-radius: 8px;
  background: var(--azure-radiance-900);
  z-index: 10;
  border: none;
  padding: 15px;
  text-decoration: none;
  &:hover{
    font-size: var(--p);
  }
`
export const ButtonConocenosdos = styled.a`
  font-size: var(--s);
  margin: auto;
  display: inline-block; /* Añadido para que el botón ocupe solo el ancho del texto */
  color: var(--mine-shaft-50);
  border-radius: 8px;
  background: var(--azure-radiance-900);
  z-index: 10;
  border: none;
  padding: 15px;
  text-decoration: none;
  &:hover {
    font-size: var(--p);
  }
`;

export const ContenedorButton =styled.section`
  display: flex;
  width: 100%;
  margin: auto;
  //background: crimson;
  //align-items: center;
  //align-self: center;
`

export const Dinamico = styled.span`
  font-size: var(--h3);
  color: var(--azure-radiance-700);
  font-size: var(--t);

`;
const dynamicTextAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const DynamicText = styled.div`
  font-size: var(--h1);
  color: var(--mine-shaft-900);
  display: inline-block;
  animation: ${dynamicTextAnimation} 5s infinite; /* Ajusta la duración según tu preferencia */
`;


// Estilos del footer
export const FooterWrapper = styled.footer`
  background-color: var(--mine-shaft-900);
  color: #fff;
  padding: 50px 0;
`;

export const Footercontainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  //background: crimson;
  width: 90%;
  margin: 0 auto 0 auto;
  @media (min-width: 100px) and (max-width: 1023px) {
    text-align: left;
  }
`;

export const LogoFooter = styled.div`
  img {
    max-width: 200px;
    height: auto;
    display: block;
    margin: 2% auto;
  }

  @media (min-width: 100px) and (max-width: 1023px) {
    max-width: none;
    width: 100%;
  }
`;
export const Column = styled.div`
  flex: 1;
  margin: 0 10px;
`;


export const ColumnTitle = styled.h4`
  font-size: 16px;
  margin-bottom: 10px;
`;
export const EmailEmpresa = styled.a`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
export const LinkList = styled.ul`
  list-style: none;
  padding: 0;

`;

export const LinkItem = styled.li`
  margin-bottom: 8px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: var(--mine-shaft-50);
  cursor: hand;

  &:hover {
    color: var(--azure-radiance-700);
  }
`;

export const CompanyInfo = styled.div`
  p {
    margin: 8px 0;
  }
`;

export const Copyright = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
`;


// disenos del separador base

export const BodySectionWrapper = styled.section`
  padding: 4%;
`;


// separador Contáctenos

export const HeroContact = styled.section`
  position: relative;
  background-image: url('https://i.pinimg.com/736x/99/48/88/994888e4d260f6b8ee72b89bfef46007--m%C3%A9xico-city-city-skylines.jpg'); /* Reemplaza con la URL de tu imagen de fondo */
  background-size: cover;
  background-position: center;
  height: 30vh;
  color: #fff;
  text-align: left;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Capa semitransparente */
  }

  @media screen and (max-width: 768px) {
    background-attachment: scroll;
    text-align: center;
  }
`

export const TitleContact = styled.h1`
  font-size: var(--h4);
  text-align: center;
  margin-top: 4%;
  padding: 0;
`

export const SubTitleContact = styled.p`
  font-size: var(--p);
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  width: 70%;
`
export const ContactCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 70vh;
  margin: 100px auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 80vh;
    width: auto;
    margin: 5%;
  }

`;

export const LeftSide = styled.div`
  width: 40%;
  background-image: url(${(props) => props.image}); // Cambia 'URL_DE_LA_IMAGEN' por la URL de tu imagen de fondo
  background-size: cover;
  background-position: center;
  position: relative;
  padding-left: 15px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    //background: crimson;
    height: 60%;
    width: auto;
    //text-align: center;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export const ContactInfo = styled.div`
  width: 60%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    //background: crimson;
    height: 50%;
    width: auto;
    text-align: center;
    font-size: 15px;
  }
`;

export const ContactTitle = styled.h4`
  font-size: var(--h4);

  @media screen and (max-width: 768px) {
    font-size: var(--p);
  }
`

export const IframeMap = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;

`

export const Title = styled.h2`
  font-size: var(--lt);
  margin: 4%;
  text-align: center;
`;

export const Subtitle = styled.p`
  font-size: var(--s);
  margin: 5px auto;
  width: 75%;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: var(--t);
  }
`;

export const ContactDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: left;
  align-items: center;
  align-self: flex-start;
  padding-left: 15%;
`;


// Estilos para servicios

export const SectionService = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.7%);
  padding: 0 0 8% 0;
  
  `


export const TitleServiceTwo = styled.h3`
  text-align: center;
  font-size: var(--h3);
  margin-top: 4%;
  padding: 0;
  color: var(--mine-shaft-800);
  @media (min-width: 100px) and (max-width: 1023px) {
    //align-content: center;
    width: 90%;
  }
`
export const TitleService = styled.h2`
  text-align: center;
  font-size: var(--h1);
  margin-top: 4%;
  padding: 0;
  color: var(--mine-shaft-800);
`
export const ParagrafService = styled.p`
  font-size: var(--p);
  text-align: center;
  margin: auto;
  width: 70%;
  padding-bottom: 4%;
`

export const ContainerService = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin: auto;
  justify-content: center;

  @media (min-width: 100px) and (max-width: 1023px) {
    flex-direction: column;
    max-width: 100%;
    margin: auto;
  }
`
export const CardService = styled.div`
  width: 100%;
  max-width: 300px; /* Define un ancho máximo para evitar que las tarjetas se expandan indefinidamente */
  height: auto;
  background: var(--mine-shaft-200);
  border-radius: var(--r0);
  padding: 2%;
  margin: 18px;
  //border: 1px solid var(--mine-shaft-200);

  @media (min-width: 100px) and (max-width: 1023px) {
    width: 80%;
    max-width: 100%;
    margin: 2% auto 2% auto ;
  }

  &:hover {
    transform: scale(1.1);
    border-radius: var(--r0);
  }

`

export const IconCard = styled.img`
  width: 30%;
  height: auto;
  margin-top: 5%;
`

export const TitleCardService = styled.h3`
  font-size: va(--h3);
  text-transform: uppercase;
`

export const ParrafoCardService = styled.p`
  font-size: var(--s);
  text-align: justify;

`


// estilos de boton de whatsapp
export const StyledWhatsAppButton = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #25d366;
  color: #fff;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #128c7e;
  }
`;

export const LogoWhatsapp = styled.img`
  width: 40px;
  height: auto;
`
// Estilos para el mini banner
export const MiniBanner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 39vh;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  padding: 5%;

  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Color negro con 50% de transparencia */
  }
`;


export const TItleminiBaner = styled.h2`
  font-size: var(--h2);
  padding: 0;
  margin: 0;
  color: var(--mine-shaft-50);
  z-index: 10;
`;

export const TextMiniBar = styled.p`
  font-size: var(--h6);
  padding: 0;
  margin: 0;
  color: var(--mine-shaft-50);
  z-index: 10;
`

export const AccessHome = styled.a`
  font-size: var(--h6);
  text-decoration: none;
  color: var(--mine-shaft-50);
  z-index: 10;
  &:hover{
    font-style: italic;
    color: var(--mine-shaft-50);
    text-decoration: underline;
  }
`


export const SectionSparate = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-left: 15%;
  padding-right: 15%;
  align-self: center;
  background-image: url(${props => props.background});
  //background-size: cover;
  background-size: 100% 100%;
  background-attachment: fixed;
  height: 50vh;
  margin-bottom: 5%;
  margin-top: 0;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Ajusta el valor alpha (último número) para cambiar la opacidad */
  }
`

export const TitleSectionSeparate = styled.p`
  color: var(--mine-shaft-50);
  z-index: 100;
  font-size: var(--h3);
  text-transform: uppercase;
  text-autospace: ideograph-alpha;
  padding: 0;
  margin: 0;
  font-weight: 100; 
`;
export const SubTitleSectionSeparate = styled.p`
  font-size: var(--h1);
  text-transform: uppercase;
  text-autospace: ideograph-alpha;
  color: var(--mine-shaft-200);
  z-index: 100;
  padding: 0;
  margin: 0;
  font-weight: 800;

`;
export const DescriptionSectionSeparate = styled.p`
  font-size: var(--p);
  font-style: italic;
  text-transform: uppercase;
  text-autospace: ideograph-alpha;
  color: var(--mine-shaft-200);
  z-index: 100;
  padding: 0;
  margin: 0;
`;


export const SectionCards = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra en el eje principal (horizontal) */
  align-items: center; /* Centra en el eje secundario (vertical) */
  width: 200%; /* Utiliza el ancho completo del contenedor padre */
  max-width: 70%; /* Establece un ancho máximo para evitar que se extienda demasiado */
  margin: auto;
  @media (min-width: 100px) and (max-width: 1023px) {
    max-width: 100%;
    margin: auto;
  }
  
`

export const CardContainerInfo = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
  position: relative;
  margin: 20px;
  @media (min-width: 100px) and (max-width: 1023px) {
    max-width: 100%;
    margin: 1%;
    
  }
`;
export const CardContainer = styled.div`
  width: 300px;
  height: 300px;
  border-radius: var(--r0);
  background-color: var(--mine-shaft-100);
  overflow: hidden;
  position: relative;
  margin: 20px;
  transition: transform 0.5s ease-out;
  cursor: pointer;
  //border: .1px solid var(--mine-shaft-900);
  flex-direction: column; /* Alinea el contenido verticalmente */
  justify-content: center; /* Centra el contenido verticalmente */

  &:hover {
    transform: scale(1.1);
    border-radius: var(--r0);
  }
  @media (min-width: 100px) and (max-width: 1023px) {
    max-width: 100%;
    margin: 1%;
    
  }
`;

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
  //background-color: var(--mine-shaft-50);
  transition: height 0.5s ease-out, width 0.5s ease-out;
  display: flex;
  flex-direction: column; /* Alinea el contenido verticalmente */
  justify-content: center; /* Centra el contenido verticalmente */
  position: absolute;
  bottom: 0;
  
  ${CardContainer}:hover & {
    height: 100%;
    width: 100%;
    background-color: var(--mine-shaft-900);
    color: #d6f3ff;
    
  }
`;

export const SectionCardCOntent = styled.section`
  text-align: center;
`

export const IconCardSection = styled.img`
  margin: 0 auto;
  width: 50px;
  height: 50px;
  padding: 0;  
`

export const TitleCardSection = styled.p`
  font-weight: 300;
  font-size: var(--lt);
  text-transform: uppercase;
  margin: 10px 0; /* Agrega espacio superior e inferior */

`
export const TitleCardSectionInfo = styled.h1`
  font-weight: 300;
  font-size: var(--h2);
  text-transform: uppercase;
  margin: 10px 0; /* Agrega espacio superior e inferior */
`
export const TitleCardSectionInfoDos = styled.h1`
  font-weight: 300;
  font-size: var(--h5);
  text-transform: uppercase;
  margin: 10px 0; /* Agrega espacio superior e inferior */
`
export const TitleCardSectionInfotres = styled.h1`
  font-weight: 300;
  font-size: var(--h5);
  text-transform: capitalize;
  font-style: italic;
  margin: 10px 0; /* Agrega espacio superior e inferior */
`
export const DescriptionCardSection = styled.p`
  font-size: var(--t);
  text-transform: uppercase;
  margin: 10px 0; /* Agrega espacio superior e inferior */

`

export const LeerDetallesCard = styled.a`
  text-decoration: none;
  color: var(--mine-shaft-900);
  ${CardContainer}:hover & {
    height: 100%;
    width: 100%;
    background-color: var(--mine-shaft-900);
    color: #d6f3ff;
    text-decoration: underline;
  }
`

export const SectionProyects = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 15%;
  padding-right: 15%;
  align-self: center;
  background-image: url(${props => props.background});
  background-size: cover;
  background-attachment: fixed;
  height: 486px;
  margin-bottom: 5%;
  margin-top: 0;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Ajusta el valor alpha (último número) para cambiar la opacidad */
  }
 `
export const ContenedorGeneral = styled.section`
    display: flex;
    //background: yellow;
    width: 100vw;
    height: 100%;
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-self: center;
        align-items: center;
    }

 
`

export const SectionInformation = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  width: auto;
  margin: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #d6f3ff;
  z-index: 150;
  &:hover{
    background: var(--azure-radiance-950);
  }
`

export const Cantidades = styled.p`
  font-size: var(--h2);
  font-weight: 200;
  padding: 0;
  margin: 0;
  
`

export const TextoInformativo = styled.p`
  font-size: var(--h4);
  padding: 0;
  margin: 0;
  text-transform: capitalize;
  
`

export const SectionServiceInfo = styled.div`
  padding: 0 0 3rem 0;
`

export const CardSectionService = styled.div`
  width: 60%;
  margin: auto;
  height: auto;
`

export const SectionText = styled.section`
  display: flex;
  flex-direction: column;
  text-align: start;
`
export const TitleTextInfo = styled.h2`
  font-size: var(--h2);
  font-weight: 200;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
`
export const TitleTextInfoComplement = styled.h1`
  font-size: var(--h1);
  //font-weight: 200;
  text-transform: uppercase;
  color: var(--mine-shaft-900);
  padding: 0;
  margin: 0;
`
export const ParafoTextInfoComplement = styled.p`
  font-size: var(--p);
  font-style: italic;
  //font-weight: 200;
  text-transform: uppercase;
  padding: 0 0 5rem 0;
  margin: 0 0 0 0;
`


export const Section = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export  const Columna1 = styled.div`
  flex: 1;
  text-align: justify;
  margin-right: 1%;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

export const Columna2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  @media (max-width: 767px) {
    padding-left: 0;
  }
`;

export const TextoArriba = styled.div`
  flex: 1;
  text-align: justify;
`;

export const TextoAbajo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Columna2Izquierda = styled.div`
  flex: 1;
  align-self: flex-start;
  @media (min-width: 768px) {
    margin-right: 1%;
  }

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

export const Columna2Derecha = styled.div`
  flex: 1;
  align-self: flex-start;

  @media (min-width: 768px) {
    margin-left: 1%;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;


// informacion de estilos sobre nosotros
export const InformativeAbout = styled.section`
    width: 75%;
    //background: crimson;
    padding: 0 5% 0 5%;
    margin: 4rem auto 4rem auto;
    text-align: center;
`
export const TitleAboutme = styled.h1`
  font-size: var(--h1);
  text-transform: capitalize;
  
`

export const ParrafosAbputme = styled.p`
    font-size: var(--p);
    text-align: justify;
    width: 100%;

`
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width:75%;
    padding: 0 5% 0 5%;
    margin: 4rem auto 4rem auto;
    
  //margin: 5% auto 0 auto;
  
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LeftColumn = styled.section`
  flex: 1;
  padding: 20px;
`;
export const LeftColumninv = styled.section`
  flex: 1;
  padding: 20px;
`;

export const RightColumn = styled.section`
  flex: 1;
  padding: 20px;

  @media (min-width: 768px) {
    order: -1; /* Cambia el orden de las columnas en pantallas grandes */
  }
`;
export const RightColumninv = styled.section`
  flex: 1;
  padding: 20px;
  text-align: end;
  @media (min-width: 768px) {
    order: -1; /* Cambia el orden de las columnas en pantallas grandes */
  }
`;

export const TitleNosotros = styled.h2`
  font-size: var(--h1);
  text-transform: capitalize;
  margin: 0;
  padding: 0;
`;

export const SubtitleNosotros = styled.h3`
  font-size: var(--h3);
  font-weight: 200;
  margin: 0;
  padding: 0;
`;

export const Description = styled.p`
  font-size: var(--p);
  //text-align: justify;
`;

export const Image = styled.img`
  border-radius: var(--r1);
  max-width: 100%;
  height: auto;
`;

export const ContainerMiniSidebar = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  word-wrap: break-word; /* Rompe las palabras si no caben en una línea */
  overflow-wrap: break-word; /* Maneja el desbordamiento de palabras */
  
  @media (min-width: 100px) and (max-width: 1023px)  {
    flex-direction: column;
  }
`
export const MiniSidebarall = styled.aside`
  border: 1px solid var(--mine-shaft-200);  // Añadido para el borde inferior
  border-radius: var(--r2);
  width: 30vw;
  padding: 2%;
  margin: 2%;
  
  @media (min-width: 100px) and (max-width: 1023px)  {
    width: 100%;
  }
`
export const TituloServicios = styled.h2`
  margin: 0;
  margin-bottom: 8%;
  border-bottom: 1px solid var(--mine-shaft-50); // Añadido para el borde inferior del título
  @media (max-width: 768px) {
    margin-bottom: 4%;
  }
`;
export const ListaSinSubrayado = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const LiCardsMini = styled.li`
  text-decoration: none;
  
`
export const MiniCard = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid var(--mine-shaft-100);
  padding: 2%;
  border-radius: var(--r1);
  height: auto;
  &:hover{
    background: var(--mine-shaft-200);
  }
  a {
    color: var(--mine-shaft-950);
    text-decoration: none;
    margin-left: 10px;
    &:hover{
      text-decoration: underline;
    }
  }
`;

export const ImgMiniCard = styled.img`
  width: 30%;
  border-radius: var(--r0);
`

export const AccesoServicio = styled.a`
  font-size: var(--p);
  word-wrap: break-word; /* Rompe las palabras si no caben en una línea */
  overflow-wrap: break-word; /* Maneja el desbordamiento de palabras */
  
`

export const ContainerDescripcionServicios = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2% 0 2% 0;
  padding: 1%;
  @media (min-width: 100px) and (max-width: 1023px) {
  }
`

export const TituloServicioDescripcion = styled.h1`
  font-size: var(--h1);
  padding: 0;
  margin: 0 0 2% 0;  
`
export const SubtituloDescripcion = styled.h4`
  font-size: var(--h4);
  font-weight: 200;
  padding: 0;
  margin: 0;
`
export const ContainerDescripcionServicios2 = styled.section`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  @media (min-width: 100px) and (max-width: 1023px) {
    flex-direction: column;
  }
`

export const TextoDescriptivoServicio = styled.div`
  font-size: var(--p);
  width: auto;
  height: auto;
  margin: 0 2% 0 0;
  text-align: justify;
  @media (min-width: 100px) and (max-width: 1023px) {
    width: 100%;
    margin: 0 2% 0 0;
  }
`

export const ImagenDescripcionServicio = styled.img`
  width: 40%;
  height: 35vh;
  border-radius: var(--r1);
  @media (min-width: 100px) and (max-width: 1023px) {
    width: 100%;
    margin-top: 2%;
    height: 50%;
    border-radius: var(--r1);
  }
`

export const Quienessomos = styled.section`
  width: 100%;
  height: auto;
  margin: 8% 0 8% 0;
`

export const ContainerAboutHome = styled.section`
  display: flex;
  width: 60%;
  margin: auto;

  @media (min-width: 100px) and (max-width: 1023px) {
    flex-direction: column;
    width: 90%;
    align-items: center;
  }
`
export const ImagenAboutHome = styled.img`
    width: 40%;
    height: auto;
  border-radius: var(--r1);
  margin-right: 2%;
  @media (min-width: 100px) and (max-width: 1023px) {
    width: 90%;
  }
`

export const ContainerInformative =styled.section`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  padding: 1rem;
  @media (min-width: 100px) and (max-width: 1023px) {
    width: 90%;
  }
  
`
export const TitleAboutHome = styled.h3`
  text-align: center;
  font-weight: 900;
  font-size: var(--h2);
  text-transform: uppercase;
  color: var(--mine-shaft-900);
  padding: 1%;
  margin: 0;
`

export const PAboutHome = styled.p`
  text-align: justify;
  font-weight: 200;
  font-size: var(--lt);
  //text-transform: uppercase;
  color: var(--mine-shaft-900);
  padding: 0 0 2% 0;
  margin: 0;
`

export const ContainerImageAboutHome = styled.section`
  display: flex;
  padding: 5%;
`

export const ImageAboutHomeValores = styled.img`
  width: 30%;
  margin: 2%;
  
`