import React, {createContext, useContext, useState} from 'react';
import services from "../assets/services.jpg";
import logo_color from "../assets/logo.png"
import logo_blanco from "../assets/logo3.png"
import logo_negro from "../assets/logo2.png"

const GlobalContext = createContext();
const ServiciosContext = createContext();
const MigracionContext = createContext();
const PoliticasContext = createContext();
const SocialContext = createContext();

export const GlobalProvider = ({children}) => {
    const [empresaInfo, setEmpresaInfo] = useState({
        nombre: 'MS-IT COMUNICACIONES',
        direccion: 'Carrera 67 # 65-22 Sur',
        celular: '3016236884',
        // telefono: '3005034762',
        whatsapp: '573016236884', // Agregar siempre 57 antes del numero sin espacios
        whatsapp_message: '¡Hola MS-IT, me gustaría obtener información de sus servicios!', // Agregar siempre 57 antes del numero sin espacios
        correo: 'comercial@ms-itsoluciones.com',
        logo_navbar: logo_blanco,
        logo_footer: logo_blanco,
    });
    const [socialLinksData, setSocialLinksData] = useState([
        {
            "label": "Facebook",
            "url": "https://www.facebook.com/profile.php?id=61550591534643&mibextid=ZbWKwL"
        },
        // {
        //     "label": "X",
        //     "url": "https://twitter.com/tu_cuenta"
        // },
        // {
        //     "label": "LinkedIn",
        //     "url": "https://www.linkedin.com/in/tu_perfil"
        // },
        {
            "label": "Instagram",
            "url": "https://www.instagram.com/msitsoluciones/?igsh=czFibnRvN3RyYmF6"
        }
        // Agrega más redes sociales según sea necesario
    ])
    const [servicios, setServicios] = useState([
        {
            "id": 1,
            "icon": logo_blanco,
            "title": "Evaluación integral",
            "subtitle": "",
            "description": "Realizamos una evaluación detallada de tus necesidades y sistemas existentes para diseñar un plan de migración personalizado.",
            "description_mini": ""
        },
        {
            "id": 2,
            "icon": logo_blanco,
            "title": "Planificación estrategica",
            "subtitle": "",
            "description": "Desarrollamos una estrategia de migración que se alinee con tus objetivos comerciales, ya sea migrar desde sistemas heredados, otros sistemas de gestión de contenido o versiones anteriores de SharePoint.",
            "description_mini": ""
        },
        {
            "id": 3,
            "icon": logo_blanco,
            "title": "Migración de contenido",
            "subtitle": "",
            "description": "Transferimos documentos, listas, bibliotecas, flujos de trabajo y otros elementos de datos de manera segura y eficiente a SharePoint.",
            "description_mini": "Holamundo"
        },
        {
            "id": 4,
            "icon": logo_blanco,
            "title": "Integración de seguridad",
            "subtitle": "",
            "description": "Implementamos medidas de seguridad sólidas para proteger tus datos confidenciales y cumplir con los requisitos de regulación.",
            "description_mini": ""
        },
        {
            "id": 5,
            "icon": logo_blanco,
            "title": "Entrenamiento y Adopción",
            "subtitle": "",
            "description": "Ofrecemos capacitación a tus usuarios para garantizar que aprovechen al máximo las capacidades de SharePoint y fomenten la adopción exitosa de la plataforma.",
            "description_mini": ""
        }
    ]);
    const [migracion, setMigracion] = useState([
        {
            "icon": logo_blanco,
            "title": "Planificación integral",
            "paragraph": "Realizamos una evaluación exhaustiva de tu infraestructura de correo electrónico existente para desarrollar un plan de migración detallado y personalizado."
            // "paragraph": "Desarrollamos estrategias completas para maximizar eficiencia y lograr objetivos. Un enfoque holístico que impulsa el éxito de tu proyecto."
        },
        {
            "icon": logo_negro,
            // "icon": "https://w7.pngwing.com/pngs/568/379/png-transparent-technology-computer-icons-technology-electronics-text-logo.png",
            "title": "Selección de estrategias",
            "paragraph": "Identificamos la estrategia de migración más adecuada para tus necesidades, ya sea migración de lotes, migración híbrida o migración por etapas."
            // "paragraph": "Identificamos cuidadosamente las tácticas más efectivas para alcanzar tus metas. Un enfoque experto para optimizar resultados y garantizar el éxito."
        },
        {
            "icon": logo_blanco,
            "title": "Migraciones de datos",
            "paragraph": "Ejecutamos la migración de datos de manera eficiente, asegurándonos de que tus correos, calendarios, contactos y otros elementos se transfieran sin pérdida de información y sin interrupciones en la comunicación."
            // "paragraph": "Garantizamos traslados fluidos y sin contratiempos. Un proceso eficiente que asegura la continuidad operativa y la satisfacción del usuario."
        },
        {
            "icon": logo_negro,
            "title": "Pruebas y Verificación",
            "paragraph": "Realizamos pruebas exhaustivas para garantizar la integridad de los datos y la funcionalidad de Microsoft 365 antes de la transición completa."
            // "paragraph": "Realizamos exhaustivas pruebas y verificación para garantizar la calidad y funcionalidad. Un proceso riguroso para asegurar el rendimiento óptimo de cada solución implementada."
        },
        {
            "icon": logo_blanco,
            "title": "Soporte continuo",
            "paragraph": "Proporcionamos soporte continuo durante y después de la migración para abordar cualquier problema y asegurarnos de que todo funcione sin problemas."
            // "paragraph": "Nuestro compromiso va más allá de la implementación. Brindamos asistencia constante para asegurar el funcionamiento ininterrumpido y la satisfacción total del cliente."
        },
        {
            "icon": logo_negro,
            "title": "Capacitaciones avanzadas",
            "paragraph": "Ofrecemos capacitación a tu personal para que puedan aprovechar al máximo las herramientas de Microsoft 365 y garantizar una transición sin complicaciones."
            // "paragraph": "Impartimos formación especializada para potenciar habilidades y maximizar el aprovechamiento de soluciones. Un enfoque educativo para el éxito sostenible de tu equipo."
        }
    ])
    const [politicasms, setPoliticasms] = useState([
        {
            "id": 1,
            "label": "Políticas de seguridad",
            "description": "Última actualización: 11/01/2024 </br></br>" +
                "En MS-IT Soluciones informáticas, nos tomamos muy en serio la seguridad de la información. Esta política tiene como objetivo garantizar la protección de los datos de nuestros usuarios y mantener la integridad y confidencialidad de la información en nuestra página web alojada en mi.com.co" +

                "<br><br> 1. Acceso y Control de la Información" +
                " <br><ul><li><b><i>Acceso Autorizado</i></b>: Solo el personal autorizado tiene acceso a la infraestructura de la página web y a la base de datos.</li><br><li><b><i>Control de Acceso</i></b>:  Se implementan medidas de control de acceso, como contraseñas seguras, autenticación de dos factores, y se limita el acceso a funciones específicas según la necesidad.</li></ul>" +
                "2. Protección contra Amenazas y Vulnerabilidades\n" +
                " <br>" +
                " <ul><li><i><b>Actualizaciones Regulares</b> </i>Se realizan actualizaciones periódicas del software, sistemas operativos y cualquier componente que pueda tener vulnerabilidades.</li></ul>" +
                "<ul><li><i><b>Monitoreo Continuo</b> </i>Implementamos sistemas de monitoreo para detectar y responder a posibles amenazas en tiempo real.</li></ul>" +
                "3. Almacenamiento de Datos" +
                " <ul><li><b>Cifrado de Datos</b><br>Los datos almacenados, especialmente aquellos de naturaleza sensible, se cifran para proteger su confidencialidad.</li> <br>" +
                "<li><b>Respaldo de Datos</b><br>Se realizan respaldos periódicos de la información para garantizar la disponibilidad y recuperación en caso de eventos imprevistos.</li></ul>" +
                " <br>4. Protección contra Ataques<br>" +
                "<ul>" +
                "<li><b>Firewalls y Filtros</b>: Se implementan firewalls y filtros para prevenir y bloquear intentos de acceso no autorizado.</li>" +
                "</ul>" +
                "<ul><li><b>Protección contra Malware</b>: Se utilizan herramientas antivirus y antimalware para escanear y proteger la página web contra amenazas.</li></ul>" +
                " <br>5. Transmisiones Seguras" +
                " <br><ul><li><b><i>Protocolos de Seguridad</i></b>: Todas las transmisiones de datos se realizan a través de protocolos seguros, como HTTPS, para proteger la información durante su tránsito.</li></ul>" +
                " <br><br>6. Política de Privacidad" +
                " <br><ul><li><i><b>Declaración de Privacidad</b></i>: Mantenemos una declaración de privacidad que informa a los usuarios sobre cómo se recopila, utiliza y protege su información.</li></ul>" +

                " <br>7. Notificación de Incidentes. <br>" +
                " <ul><li><b><i>Reporte de Incidentes</i></b>: En caso de una brecha de seguridad, se notificará a los usuarios afectados y se tomarán medidas para remediar la situación.</li></ul>" +
                " <br>8. Actualización de la Política: <br>" +
                " <ul><li>Nos reservamos el derecho de actualizar esta política de seguridad. Cualquier cambio significativo se notificará a través de nuestro sitio web.</li></ul>" +
                "Gracias por confiar en www.ms-itsoluciones.com.\n",
            "imagen": services,
        },
        {
            "id": 2,
            "label": "Políticas de Cookies",
            "description": "Última actualización: 11/01/24 <br/> <br/>" +
                "Bienvenido/a a MS-IT Soluciones. <br><br> Esta política explica cómo utilizamos las cookies y otras tecnologías similares en nuestro sitio web. Al continuar utilizando nuestro sitio, aceptas el uso de cookies de acuerdo con esta política. <br>" +
                "<br> 1. ¿Qué son las Cookies?\n" +
                "Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestro sitio web. Estas cookies nos permiten reconocerte y personalizar tu experiencia de usuario.\n" +
                "<br><br> 2. Tipos de Cookies que Utilizamos:\n" +

                "<ul>" +
                "<li><b>Cookies Esenciales.</b> <br>Estas cookies son necesarias para el funcionamiento básico del sitio y te permiten navegar y utilizar funciones esenciales.</li><br>" +
                "<li><b>Cookies de Rendimiento</b> <br>Utilizamos cookies de rendimiento para recopilar información sobre cómo interactúas con nuestro sitio, lo que nos ayuda a mejorar su funcionamiento. </li><br>" +
                "<li><b>Cookies de Funcionalidad:</b><br>Estas cookies permiten recordar tus preferencias y personalizar tu experiencia en nuestro sitio.</li><br>" +
                "<li><b>Cookies de Terceros:</b>Trabajamos con terceros, como proveedores de servicios de análisis y publicidad, que también pueden colocar cookies en tu dispositivo para diversos fines.</li><br>" +
                "</ul> " +
                "3. Finalidades de las Cookies." +
                "<ul>" +
                "<li>Mejorar la experiencia del usuario</li>" +
                "<li>Analizar el tráfico y el rendimiento del sitio</li>" +
                "<li>Personalizar contenido y anuncios</li>" +
                "<li>Proporcionar funciones de redes sociales</li>" +
                "<li>Garantizar la seguridad del sitio</li>" +
                "</ul>" +
                "<br> 4. Control de Cookies. <br>" +
                "Puedes gestionar las preferencias de cookies a través de la configuración de tu navegador. Ten en cuenta que la desactivación de ciertas cookies puede afectar la funcionalidad del sitio.\n" +
                "<br><br>5. Terceros y Enlaces. <br>" +
                "Nuestro sitio puede contener enlaces a sitios web de terceros. No nos hacemos responsables de las prácticas de privacidad de estos sitios y te recomendamos revisar sus políticas.\n" +
                "<br><br>6. Cambios en la Política. <br>" +
                "Nos reservamos el derecho de actualizar esta política de cookies. Cualquier cambio significativo se notificará a través de nuestro sitio web.\n" +
                "<br><br>7. Contacto. <br>" +
                "Si tienes alguna pregunta sobre nuestra política de cookies, por favor contáctanos en <b>ms-itsoluciones@outlook.com</b>" +
                "br Gracias por confiar en MS IT Soluciones informáticas.\n",
            "imagen": services,
        },
        {
            "id": 3,
            "label": "Políticas tratamiento de datos",
            "description": "\n" +
                "1. Responsable del Tratamiento de Datos Personales" +
                " MS-IT Soluciones informáticas <br>" +
                " <br>  2. Objetivo de la Política <br>" +
                "La presente política tiene como objetivo establecer los principios y procedimientos que [MS-IT Soluciones informáticas] seguirá en el tratamiento de los datos personales de los usuarios de nuestro sitio web, con el fin de garantizar el cumplimiento de la normativa vigente en materia de protección de datos en Colombia, especialmente la Ley 1581 de 2012 y sus decretos reglamentarios.\n" +
                " <br><br> 3. Datos Personales que Recopilamos\n" +
                "En MS-IT Soluciones informáticas recopilamos los siguientes tipos de datos personales:\n" +
                " <ul><li>Nombre y apellidos.</li><li>Dirección de correo electrónico.</li><li>Información de contacto, como dirección y número de teléfono.</li><li>Información demográfica, como edad, género y ubicación.</li><li>Información de inicio de sesión, incluyendo nombre de usuario y contraseña.</li></ul>" +
                " <br> 4. Finalidad del Tratamiento de Datos\n" +
                "Los datos personales recopilados se utilizan para los siguientes fines:\n" +
                " <ul><li>Para la prestación de servicios y productos.</li><li>Para la gestión de cuentas de usuario.</li><li>Para el envío de información sobre nuestros productos y servicios.</li><li>Para la personalización de la experiencia del usuario en nuestro sitio web.</li><li>Para el análisis y mejora de nuestros servicios.</li></ul>" +
                " <br>5. Consentimiento del Titular de los Datos\n" +
                "Al utilizar nuestro sitio web y proporcionar sus datos personales, el usuario otorga su consentimiento para el tratamiento de estos de acuerdo con los fines establecidos en esta política, cumpliendo con lo establecido en la Ley 1581 de 2012 y sus decretos reglamentarios.\n" +
                " <br><br>6. Derechos de los Titulares de Datos\n" +
                "Los titulares de datos personales tienen los siguientes derechos, en conformidad con la Ley 1581 de 2012:\n" +
                " <ul><li>Derecho de acceso: Los titulares pueden solicitar información sobre los datos personales que tenemos sobre ellos.</li><li>Derecho de rectificación: Los titulares pueden corregir datos personales inexactos.</li><li>Derecho de supresión: Los titulares pueden solicitar la eliminación de sus datos personales, cuando sea legalmente posible.</li><li>Derecho de oposición: Los titulares pueden oponerse al tratamiento de sus datos personales.</li><li>Derecho a la portabilidad: Los titulares pueden solicitar la transferencia de sus datos personales a otra entidad.</li></ul>\n" +
                " <br>7. Seguridad de los Datos\n" +
                "Implementamos medidas de seguridad adecuadas para proteger los datos personales de nuestros usuarios contra accesos no autorizados, divulgación, alteración o destrucción, conforme a lo establecido en la normativa colombiana.\n" +
                " <br><br>8. Modificaciones a la Política de Tratamiento de Datos\n" +
                "MS-IT Soluciones informáticas se reserva el derecho de actualizar esta política en cualquier momento, notificando a los usuarios a través de nuestro sitio web, de acuerdo con lo establecido en la Ley 1581 de 2012.\n" +
                " <br><br>9. Contacto\n" +
                "Si tienes alguna pregunta o inquietud acerca de nuestra política de tratamiento de datos personales, por favor contáctanos a través de la información de contacto proporcionada anteriormente.\n" +
                "\n",
            "imagen": services,
        }
    ])
    return (
        <GlobalContext.Provider value={[empresaInfo, setEmpresaInfo]}>
            <ServiciosContext.Provider value={[servicios, setServicios]}>
                <MigracionContext.Provider value={[migracion, setMigracion]}>
                    <PoliticasContext.Provider value={[politicasms, setPoliticasms]}>
                        <SocialContext.Provider value={[socialLinksData, setSocialLinksData]}>
                            {children}
                        </SocialContext.Provider>
                    </PoliticasContext.Provider>
                </MigracionContext.Provider>
            </ServiciosContext.Provider>
        </GlobalContext.Provider>
    );
};

export const useGlobalContext = () => {
    return useContext(GlobalContext, ServiciosContext);
};

export const useServiciosContext = () => {
    return useContext(ServiciosContext);
};

export const useMigracionContext = () => {
    return useContext(MigracionContext);
};
export const usePoliticasContext = () => {
    return useContext(PoliticasContext);
};
export const useSocialContext = () => {
    return useContext(SocialContext);
};