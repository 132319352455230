import React from "react";
import services from '../../assets/services.jpg'
import services_happy from '../../assets/service_happy.png'
import {
    AccessHome,
    MiniBanner,
    TextMiniBar,
    TItleminiBaner,
} from "../../theme/theme.elements";
import {Link} from "react-router-dom";
import {SectionSeparate} from "../../components/sectionSeparate";
import {Servicesone} from "./servicesone";
import {Servicestow} from "./servicestow";
import {Projects} from "../../components/projects";
import {ServiceDescription} from "./serviceDescription";

function Services() {
    window.scrollTo(0, 0);
    return (
        <React.Fragment>
            <MiniBanner image={services}>
                <TItleminiBaner>Servicios</TItleminiBaner>
                <TextMiniBar><AccessHome as={Link} to={"/"}>Inicio</AccessHome> / Nuestros servicios.</TextMiniBar>
            </MiniBanner>
            <Servicesone/>
            <SectionSeparate imagen={services_happy} titleseparate={"MIGRACIONES DE CORREO"}
                             titleFocalice={'EXPERTOS EN MIGRACIÓN'}
                             description={'Optimiza Oportunidades, Impulsa la Eficiencia Empresarial con Nuestros Correos Corporativos de Excelencia.'}/>

            <Servicestow/>
            <Projects/>
            <ServiceDescription/>
        </React.Fragment>
    )
}

export {Services};