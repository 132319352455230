import React from "react";
import {
    AccessHome,
    BodySectionWrapper,
    ContainerMiniSidebar,
    MiniBanner,
    TextMiniBar,
    TItleminiBaner
} from "../../theme/theme.elements";
import services from "../../assets/services.jpg";
import {Link, useParams} from "react-router-dom";
import MiniSidebarPolicy from "./miniSidebarPolicy";
import {Description_policy} from "./description_policy";

function PoliticasPrivacidad() {
    const {id} = useParams();
    window.scrollTo(0, 0);
    return (
        <React.Fragment>
            <MiniBanner image={services}>
                <TItleminiBaner>Nuestras politicas</TItleminiBaner>
                <TextMiniBar><AccessHome as={Link} to={"/"}>Inicio</AccessHome> / Politicas.</TextMiniBar>
            </MiniBanner>
            <ContainerMiniSidebar>
                <MiniSidebarPolicy valor={id}/>
                <Description_policy valor={id}/>
            </ContainerMiniSidebar>
        </React.Fragment>
    )
}

export {PoliticasPrivacidad};