import React from "react";
import {
    AccessHome,
    ContactCardContainer, ContactDetails,
    ContactInfo, ContactTitle,
    IframeMap,
    LeftSide, MiniBanner,
    Overlay,
    Subtitle, SubTitleContact, TextMiniBar,
    Title, TItleminiBaner
} from "../theme/theme.elements";
import PhoneIcon from '@mui/icons-material/Phone';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import contactenos from "../assets/contactenos.png";
import contactenosform from "../assets/contactform.jpg";
import {Link} from "react-router-dom";
import {useGlobalContext} from "../globalcontext/globalcontext";
import SettingsCellRoundedIcon from '@mui/icons-material/SettingsCellRounded';

function Contact() {
    const [empresaInfo,] = useGlobalContext();
    window.scrollTo(0, 0);

    return (
        <React.Fragment>
            <MiniBanner image={contactenos}>
                <TItleminiBaner>Contáctenos</TItleminiBaner>
                <TextMiniBar><AccessHome as={Link} to={"/"}>Inicio</AccessHome> / Contáctenos.</TextMiniBar>
            </MiniBanner>
            <SubTitleContact>
                "Nos encantaría escucharte. Si tienes alguna pregunta, comentario o simplemente quieres decir hola, no dudes en escribirnos. Estamos aquí para ayudar y responder a todas tus inquietudes. Tu mensaje es importante para nosotros, y nos esforzaremos por responder lo antes posible. ¡Gracias por ponerte en contacto con nosotros!"
            </SubTitleContact>
            <ContactCardContainer>
                <LeftSide image={contactenosform}>
                    <Overlay>
                        <Title>Información de contacto</Title>
                        {/*<Subtitle>*/}
                        {/*    Nos encantaría escucharte. Si tienes alguna pregunta, comentario o simplemente quieres decir hola, no dudes en escribirnos. Estamos aquí para ayudar y responder a todas tus inquietudes.*/}
                        {/*</Subtitle>*/}

                        <ContactDetails>
                            <SettingsCellRoundedIcon/> <span>- {empresaInfo.celular}</span>
                        </ContactDetails>
                        {/*<ContactDetails>*/}
                        {/*    <PhoneIcon/> <span>- {empresaInfo.telefono}</span>*/}
                        {/*</ContactDetails>*/}
                        <ContactDetails>
                            <MarkEmailReadIcon/> <span>- {empresaInfo.correo}</span>
                        </ContactDetails>
                        <ContactDetails>
                            <LocationOnIcon/> <span>- {empresaInfo.direccion}</span>
                        </ContactDetails>
                    </Overlay>
                </LeftSide>
                <ContactInfo>
                    <ContactTitle>!Hola!,Ya puedes ubicarnos.</ContactTitle>
                    <IframeMap
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d565.7007411716716!2d-74.15660299644226!3d4.587973068801359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9f1d9e200bed%3A0x24aeb4f210a79e25!2sCra.%2067%20%2365-22%2C%20Bogot%C3%A1!5e0!3m2!1ses!2sco!4v1705540860213!5m2!1ses!2sco"
                    ></IframeMap>
                </ContactInfo>
            </ContactCardContainer>
        </React.Fragment>
    )
}

export {Contact};