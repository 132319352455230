import React from "react";
import {
    Consectiodos,
    H3sectiodos,
    Imgsectiondos,
    Psectiodos,
    SectionContainerDos,
    Sectiondos
} from "../../theme/home.elements";
import servicios from "../../assets/services.jpg"

function QuienesSomosHome() {
    return (
        <React.Fragment>
            <Sectiondos>
                <SectionContainerDos>
                    <Consectiodos>
                        <H3sectiodos>
                            OPTIMICE SU INFRAESTRUCTURA DE CORREO ELECTRÓNICO CON MS-IT
                        </H3sectiodos>
                        <Psectiodos>
                            MS IT ofrece servicios especializados de migración de correo electrónico que incluyen
                            planificación exhaustiva, evaluación de la infraestructura, pruebas y garantía de calidad,
                            junto con asistencia continua. Nuestras soluciones mejoran la eficiencia operativa de su
                            empresa, transformando su infraestructura de correo electrónico en un motor de
                            productividad.
                        </Psectiodos>
                    </Consectiodos>
                    <Imgsectiondos
                        src={servicios} alt="Servicios home"/>
                </SectionContainerDos>

            </Sectiondos>
        </React.Fragment>

    );
}

export {QuienesSomosHome};